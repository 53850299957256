import React from 'react';
import LandingLayout from '@/dealroadshow/ui/components/Landing/Layout';
import PageNotFound from '@/Framework/UI/Pages/PageNotFound';
import styles from './pageNotFound.scss';
import { useSessionContext } from '@/users/application/Session/SessionContext';
import finsightWebUrl from '@/finsight/infrastructure/finsightWebUrl';

function PageNotFoundWrapper() {
  const { currentUser, currentUserFetching } = useSessionContext();

  const pageNotFoundContent = (
    <>
      The page you are looking for could not be found.
      <p className={ styles.errorMsg }>
        <span>If you believe this is an error, please</span>
        &nbsp;
        <a href={ finsightWebUrl.getContactUsUrl() }>
          contact us
        </a>.
      </p>
    </>
  );

  if (!currentUserFetching) {
    if (currentUser) {
      return (
        <PageNotFound
          className={ styles.pageNotFoundWrp }
          content={ pageNotFoundContent }
          button={ null }
        />
      );
    }
    return (
      <LandingLayout>
        <PageNotFound />
      </LandingLayout>
    );
  }
  return null;
}

export default PageNotFoundWrapper;
